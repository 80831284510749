<script setup lang="ts">
import Spinner from "../spinner/index.vue";

export type ButtonThemes = "primary" | "danger" | "white";
const props = withDefaults(
  defineProps<{
    label: string;
    type?: "button" | "submit";
    theme?: ButtonThemes;
    size?: "small" | "medium" | "large";
    disabled?: boolean;
    loading?: boolean;
    to?: string;
    rounded?: boolean;
    link?: boolean;
    outline?: boolean;
    tooltip?: string;
    noPermission?: boolean;
    noBreakText?: boolean;
    fontNormal?: boolean;
    iconName?: string;
    iconSize?: number;
    iconColor?: string;
  }>(),
  {
    type: "button",
    theme: "primary",
    size: "medium",
    iconSize: 24,
    iconColor: "text-primary",
  }
);
const emit = defineEmits<{
  (e: "click"): void;
}>();
</script>

<template>
  <button
    :class="`btn max-w-full w-[200px] ${theme} ${link && 'link'} ${size} ${
      (disabled || noPermission) && 'disabled'
    } ${loading && 'loading'} ${rounded ? 'rounded-full' : 'rounded-[7px]'} ${
      outline && 'outlined'
    } ${noBreakText && 'whitespace-nowrap'} ${
      fontNormal ? 'font-normal' : 'font-bold'
    }`"
    :type="type"
    :disabled="disabled || noPermission"
    @click="
      noPermission || disabled || loading
        ? null
        : to
        ? navigateTo(to)
        : emit('click')
    "
    :title="tooltip || noPermission ? 'Você não possui permissão!' : undefined"
  >
    <Spinner
      v-if="loading"
      class="mr-3"
      :theme="outline ? 'primary' : 'white'"
    />
    <slot name="icon" v-else>
      <AtomsIcon
        v-if="iconName"
        :name="iconName"
        :size="iconSize"
        :class="
          iconColor === 'text-primary' && (loading || disabled || noPermission)
            ? 'text-primary-light'
            : iconColor
        "
        class="button-link__icon"
      />
    </slot>
    {{ label }}
  </button>
</template>

<style scoped lang="scss">
.btn {
  @apply border-0 outline-0 text-[16px] transition duration-300 leading-5 flex items-center justify-center text-center relative;

  &.disabled,
  &.loading {
    @apply cursor-not-allowed;
  }

  &.small {
    @apply px-[10px] py-[5px] text-[12px];
  }
  &.medium {
    @apply px-[18px] py-[14px];
  }
  &.large {
    @apply px-[24px] py-[18px];
  }

  &.primary {
    &.link:not(.outlined) {
      @apply text-primary hover:text-primary-dark transition bg-transparent;
      &.disabled,
      &.loading {
        @apply text-primary-light;
      }
    }
    &:not(.outlined):not(.link) {
      &:hover:not(.disabled) {
        @apply bg-primary-dark;
      }
      @apply bg-primary text-white;
      &.disabled,
      &.loading {
        @apply bg-primary-light;
      }
    }
    &.outlined:not(.link) {
      &:hover:not(.disabled):not(.loading) {
        @apply border-primary-dark text-primary-dark bg-[rgba(0,102,255,0.08)];
      }
      @apply border border-primary text-primary bg-transparent;
      &.disabled,
      &.loading {
        @apply border-primary-light text-primary-light;
      }
    }
  }
  &.danger {
    &.link:not(.outlined) {
      @apply text-danger hover:text-danger-dark bg-transparent;
      &.disabled,
      &.loading {
        @apply text-danger-light;
      }
    }
    &:not(.outlined):not(.link) {
      &:hover:not(.disabled) {
        @apply bg-danger-dark;
      }
      @apply bg-danger text-white;
      &.disabled,
      &.loading {
        @apply bg-danger-light;
      }
    }
    &.outlined:not(.link) {
      &:hover:not(.disabled):not(.loading) {
        @apply border-danger-dark text-danger-dark bg-[rgba(230,108,100,0.08)];
      }
      @apply border border-danger text-danger bg-transparent;
      &.disabled,
      &.loading {
        @apply border-danger-light text-danger-light;
      }
    }
  }

  &.white {
    &.link:not(.outlined) {
      @apply text-white bg-transparent;
      &.disabled,
      &.loading {
        @apply text-white;
      }
    }
    &:not(.outlined):not(.link) {
      &:hover:not(.disabled) {
        @apply bg-white;
      }
      @apply bg-white text-primary;
      &.disabled,
      &.loading {
        @apply bg-primary-light;
      }
    }
    &.outlined:not(.link) {
      &:hover:not(.disabled):not(.loading) {
        @apply bg-[rgba(230,230,230,0.08)];
      }
      @apply border border-white text-white bg-transparent;
      &.disabled,
      &.loading {
        @apply border-white text-white;
      }
    }
  }
}
</style>
